import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ReactComponent as IconCartaoCredito } from '../../assets/icons/icon-cartao-credito.svg';
import { ReactComponent as IconCartaoDebito } from '../../assets/icons/icon-debito-conta.svg';
import { ReactComponent as IconPix } from '../../assets/icons/icon-pix.svg';

import { ReactComponent as IconItau } from '../../assets/icons/banco-itau.svg';
import { ReactComponent as IconBradesco } from '../../assets/icons/banco-bradesco.svg';
import { ReactComponent as IconInter } from '../../assets/icons/banco-inter.svg';
import { ReactComponent as IconNubank } from '../../assets/icons/banco-nubank.svg';
import { ReactComponent as IconC6 } from '../../assets/icons/banco-c6.svg';
import { ReactComponent as IconBBrasil } from '../../assets/icons/banco-brasil.svg';
import { ReactComponent as IconMastercard } from '../../assets/icons/mastercard.svg';
import { ReactComponent as IconVisa } from '../../assets/icons/visa.svg'
import { ReactComponent as IconElo } from '../../assets/icons/elo.svg'
import { ReactComponent as IconDebit3ds } from '../../assets/icons/debito-3ds.svg';
// import { ReactComponent as IconSantander } from '../../assets/icons/banco-santander.svg';
// import { ReactComponent as IconBrasil } from '../../assets/icons/banco-do-brasil.svg';


import { Button, Form, Row, Col, Spinner, Accordion, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { dateComparePeriod, doubleToString } from '../../utils/utils';
import { Configuration } from '../../modelos';
import InputMask from "react-input-mask";
import ReactDOM from 'react-dom';
import { Logic } from './logic';
import CheckboxComponent from "../checkbox.component";
import { ModalLoading3DS } from "../modal_loading_3ds";
import { ModalAvisoPixComponent } from "../modal_aviso_pix_componente";
import { CheckoutContext } from "../../context/checkout.provider";

export const MetodoPagamentoComponente = (props: {
    configuration: Configuration,
    handleSubmit: any,
    disableFields: any,
    formularioPagamento?: any,
    desabilitarEndereco?: any,
    showError?: any,
    alterarShowError?: any,
    loading?: any,
    alterarLoading?: any,
    iniCreditoAberto?: boolean,
    canFocusNumero?: boolean,
    alterarCanFocusNumero?: any
    valorRefuturiza?: any,
    alterarValorRefuturiza?: any,
    recaptchaRef?: any,
    isRefiliacao?: any,
}) => {
    const form = useRef<HTMLFormElement>(null);
    const componentLogic =
        Logic({
            alterarShowError: props.alterarShowError,
            configuration: props.configuration,
            desabilitarEndereco: props.desabilitarEndereco,
            form: form,
            formularioPagamento: props.formularioPagamento,
            handleSubmit: props.handleSubmit,
            valorRefuturiza: props.valorRefuturiza,
            alterarValorRefuturiza: props.alterarValorRefuturiza,
            alterarLoading: props.alterarLoading,
            recaptchaRef: props.recaptchaRef
        });

    const [bankSelected, setBankSelected] = useState('');
    const [showMeDebitCard, setShowMeDebitCard] = useState(false);
    const [showCashbackEDT, setShowCashbackEDT] = useState(false);

    const {
        cashbackEnergiadetodos,
        setCashbackEnergiadetodos,
    } = useContext(CheckoutContext);

    useEffect(() => {
        if(componentLogic.idpvD3S)
            setBankSelected('outrosbancos');
    }, [])

    useLayoutEffect(() => {
        if (props.iniCreditoAberto)
            componentLogic.alterarMostrarCredito(props.iniCreditoAberto);
    }, [componentLogic, props.iniCreditoAberto]);

    useEffect(() => {       
        componentLogic.setBankName(bankSelected);
    }, [bankSelected]);

    useEffect(() => {
        if(cashbackEnergiadetodos) {
            setShowCashbackEDT(true);
        }
    }, [cashbackEnergiadetodos])

    const cartao = () =>
        <>
            {
                componentLogic.method === 'credito' && dateComparePeriod(`10/${process.env.REACT_APP_ENVIRONMENT === "dev" ? '04' : '05'}/2023`, "01/01/2040").insidePeriod && !props.isRefiliacao ? 
                    <>
                    <Row className="ps-1 mb-3 pt-4">
                        <strong className="text-muted">Incluir opcionais</strong>
                    </Row>
                    <Row className="ps-1">
                        <Col xs={8} sm={8}>
                            <CheckboxComponent
                                title="refuturiza"
                                value={componentLogic.valorRefuturiza}
                                customLabel={
                                    <>
                                    Incluir acesso a Plataforma
                                    <br />
                                    de Cursos por {dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '03/11/2024' : '03/14/2024'}`, "03/15/2024").insidePeriod ? '60' : '30'} dias 
                                    <br />
                                    grátis*
                                        <>
                                            <OverlayTrigger
                                                key={'top'}
                                                placement={'top'}
                                                delay={{ show: 0, hide: 400 }}
                                                overlay={
                                                    <Tooltip className='tooltip refuturiza'>
                                                        Plataforma de Cursos Online e Vagas de emprego. 
                                                        <br />
                                                        Mais de 1.300 cursos com certificado reconhecido em 45 áreas de conhecimento.
                                                    </Tooltip>
                                                }                                >
                                                <img className='px-1' src="/todos/refuturiza-iconButton.png" alt="Botão com informacoes do Refuturiza." />
                                            </OverlayTrigger>
                                        </>
                                    </>
                                }
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = event.target.checked;
                                    componentLogic.alterarValorRefuturiza(value)
                                }}
                            />
                        </Col>

                        <Col xs={4} sm={4} className="ms-n5">
                            <img className='px-2 pt-1' src="/todos/refuturiza-logo.png" alt="Imagem do Refuturiza." />
                        </Col>
                    </Row>
                    <div className="row">
                        <div className="col">
                            <small className="form-check-label">
                                <i>*Após o período de teste, será cobrado R$ 12,99 ao mês.</i>
                            </small>
                        </div>
                    </div>
                    <hr className="m-2 mt-4 mb-4 text-muted" />
                    </>
                    :
                    <><hr className="m-2 mt-4 mb-4 text-muted" /></>
            }

            {showCashbackEDT ?
                <>
                <div className="row ps-1 mb-3 pt-2 align-items-center">
                    <div className="col">
                        <CheckboxComponent
                            title="energiadetodos"
                            value={componentLogic.valorCashbackEDT}
                            customLabel={<>Quero receber 12% de cashback com a minha conta de energia</>}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.checked;
                                componentLogic.alterarValorCashbackEDT(value)
                            }}
                        />
                    </div>
                    <div className="col">
                        <img className='px-2 pt-1 img-fluid' src="/todos/logo-energiadetodos.webp" alt="Energia de TODOS." />
                    </div>
                </div>
    
                <hr className="m-2 mt-4 mb-4 text-muted" />
                </>
                :
                <></>

            }



            {
                componentLogic.method === 'credito' || componentLogic.bankName === 'bradesco' || componentLogic.bankName === 'itau' || componentLogic.bankName === 'outrosbancos' ? (
                    <>
                        <div id="credit" className={"accordion-collapse collapse pt-2" + (componentLogic.mostrarCredito === true && componentLogic.mostrarDebito === false && componentLogic.mostrarPix === false ? " show" : "")}>
                            {/* <Form ref={form} noValidate onSubmit={componentLogic.bankName !== 'outrosbancos' || !componentLogic.idpvD3S ? componentLogic.handleSubmit : componentLogic.handle3DSSubmit}> */}
                            <Form ref={form} noValidate onSubmit={componentLogic.bankName !== 'outrosbancos' || !componentLogic.idpvD3S ? componentLogic.handleSubmit : componentLogic.handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Número do cartão</Form.Label>
                                    <Form.Control
                                        ref={(refCep: any) => {
                                            if (refCep && window.innerWidth < 1199.98 && props.canFocusNumero) {
                                                if (props.alterarCanFocusNumero) {
                                                    props.alterarCanFocusNumero(false);
                                                }
                                                (ReactDOM.findDOMNode(refCep) as any).focus();
                                            }
                                        }}
                                        as={InputMask}
                                        maskChar=''
                                        mask="9999 9999 9999 9999"
                                        type="text"
                                        placeholder="0000 0000 0000 0000"
                                        value={componentLogic.numeroCartao}
                                        onFocus={() => componentLogic.enviarEtapaLead()}
                                        onChange={(event) => componentLogic.alterarNumeroCartao(event.target.value)}
                                        onBlur={componentLogic.verificarNumeroCartao}
                                        isValid={componentLogic.numeroCartaoValido}
                                        isInvalid={componentLogic.numeroCartaoInvalido}
                                        disabled={props.disableFields}
                                        enterKeyHint="next"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter')
                                                document.getElementById("nomeTitular")?.focus();
                                        }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{componentLogic.numeroCartaoMensagemErro}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome do titular</Form.Label>
                                    <Form.Control
                                        id="nomeTitular"
                                        type="text"
                                        placeholder="ex. Maria Lucia Almeida "
                                        value={componentLogic.nome}
                                        onFocus={() => props.desabilitarEndereco ? props.desabilitarEndereco() : {}}
                                        onChange={(event) => componentLogic.alterarNome(event.target.value)}
                                        onBlur={componentLogic.verificarNome}
                                        isValid={componentLogic.nomeValido}
                                        isInvalid={componentLogic.nomeInvalido}
                                        disabled={props.disableFields}
                                        enterKeyHint="next"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter')
                                                document.getElementById("validadeCartao")?.focus();
                                        }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{componentLogic.nomeMensagemErro}</Form.Control.Feedback>
                                </Form.Group>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Validade</Form.Label>
                                            <Form.Control
                                                id="validadeCartao"
                                                as={InputMask}
                                                maskChar=''
                                                mask="99/99"
                                                type="text"
                                                placeholder="10/10"
                                                value={componentLogic.validade}
                                                onFocus={() => props.desabilitarEndereco ? props.desabilitarEndereco() : {}}
                                                onChange={(event) => componentLogic.alterarValidade(event.target.value)}
                                                onBlur={componentLogic.verificarValidade}
                                                isValid={componentLogic.validadeValido}
                                                isInvalid={componentLogic.validadeInvalido}
                                                disabled={props.disableFields}
                                                enterKeyHint="next"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter')
                                                        document.getElementById("cvvCartao")?.focus();
                                                }}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">{componentLogic.validadeMensagemErro}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        {

                                            componentLogic.bankName !== 'itau' ? (
                                                <Form.Group className="mb-3">
                                                    <Form.Label>CVV</Form.Label>
                                                    <Form.Control
                                                        id="cvvCartao"
                                                        as={InputMask}
                                                        maskChar=''
                                                        mask="999"
                                                        type="text"
                                                        placeholder="123"
                                                        value={componentLogic.cvv}
                                                        onFocus={() => props.desabilitarEndereco ? props.desabilitarEndereco() : {}}
                                                        onChange={(event) => componentLogic.alterarCvv(event.target.value)}
                                                        onBlur={componentLogic.verificarCvv}
                                                        isValid={componentLogic.cvvValido}
                                                        isInvalid={componentLogic.cvvInvalido}
                                                        disabled={props.disableFields}
                                                        enterKeyHint="next"
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter')
                                                                document.getElementById("cpfCartao")?.focus();
                                                        }}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">{componentLogic.cvvMensagemErro}</Form.Control.Feedback>
                                                </Form.Group>
                                            ) : (
                                                <></>
                                            )
                                        }

                                    </Col>
                                </Row>
                                <Form.Group className="mb-4">
                                    <Form.Label>CPF</Form.Label>
                                    <Form.Control
                                        id="cpfCartao"
                                        as={InputMask}
                                        maskChar=''
                                        mask="999.999.999-99"
                                        type="text"
                                        placeholder="000.000.000-00"
                                        value={componentLogic.cpf}
                                        onFocus={() => props.desabilitarEndereco ? props.desabilitarEndereco() : {}}
                                        onChange={(event) => componentLogic.alterarCpf(event.target.value)}
                                        onBlur={componentLogic.verificarCpf}
                                        isValid={componentLogic.cpfValido}
                                        isInvalid={componentLogic.cpfInvalido}
                                        disabled={props.disableFields}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{componentLogic.cpfMensagemErro}</Form.Control.Feedback>
                                </Form.Group>
                                {props.showError ?
                                    <p className="mt-3 px-3 text-center">
                                        <strong className="text-danger">
                                            Ops, ocorreu um erro na transação, tente mais tarde!
                                        </strong>
                                    </p> : null}
                                <div className="d-grid pt-3">
                                    <Button variant="secondary" size="lg" type="submit" className='d-flex justify-content-center' disabled={props.loading}>
                                        {props.loading ?
                                            <Spinner animation="border" role="status" variant="light" className='position-absolute'>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner> :
                                            "Solicitar cartão"}
                                    </Button>
                                </div>
                            </Form>
                        </div >
                    </>

                ) : (
                    <>
                    </>
                )
            }

            {
                componentLogic.method === 'pix' ? 
                    <div id="pix" className={"accordion-collapse collapse pt-2 show"}>
                        <Form ref={form} noValidate onSubmit={componentLogic.handlePixSubmit}>
                            <div className="d-grid pt-3">
                                <Button variant="secondary" size="lg" type="submit" className='d-flex justify-content-center' disabled={props.loading}>
                                    {props.loading ?
                                        <Spinner animation="border" role="status" variant="light" className='position-absolute'>
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :
                                        "Gerar Pix"}
                                </Button>
                            </div>
                        </Form>
                    </div >
                :
                <></>
            }
        </>

    return (
        <>
            <div id="threeDSFingerPrint_result"></div>
            <div id="threeDSFingerPrint_result2"></div>
            <Accordion className='accordion-card'>
                <Accordion.Item eventKey="credito" className="mb-3">
                    <Accordion.Header className='payment-accordion'>
                        <div id="cardPayment" className="card-payment-method-accordion" onClick={() => { componentLogic.setMethod('credito'); componentLogic.setBankName('') }} style={{ borderColor: componentLogic.mostrarCredito === true && componentLogic.mostrarDebito === false ? "#C4C4C4" : "#F2F2F2" }}>
                            <IconCartaoCredito className="me-3" fill={props.configuration && props.configuration.theme ? props.configuration.theme!.primary : ''} />
                            Cartão de crédito
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className='accordion-body-card mb-3'>
                        {cartao()}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="debito" className="mb-3">
                    <Accordion.Header className='payment-accordion'>
                        <div id="cardPayment" className="card-payment-method-accordion" onClick={() => {componentLogic.setMethod('debito'); componentLogic.setBankName('outrosbancos'); setBankSelected('outrosbancos')}} style={{ borderColor: componentLogic.mostrarCredito === true && componentLogic.mostrarDebito === false ? "#C4C4C4" : "#F2F2F2" }}>
                            <IconCartaoDebito className="me-3" fill={props.configuration && props.configuration.theme ? props.configuration.theme!.primary : ''} />
                            Cartão de débito
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className='accordion-body-card'>

                        <Form.Group>
                            {/* <Form.Label className="pt-4">Selecione o seu banco</Form.Label> */}

                            {/* <Dropdown className="mb-2">
                            <Dropdown.Toggle id={'SelectBank'} className="is-valid text-dark">
                                {bankSelected === '' ? "Selecione" : <></>}
                                {bankSelected === 'itau' ? <div className="d-flex" id="itau">
                                        <IconItau />
                                        <span className="ms-2">Itaú</span>
                                    </div> : <></>}
                                {bankSelected === 'bradesco' ? <div className="d-flex" id="bradesco">
                                        <IconBradesco />
                                        <span className="ms-2">Bradesco</span>
                                    </div> : <></>}
                                {bankSelected === 'outrosbancos' ? <div className="d-flex" id="outrosbancos">
                                        <IconDebit3ds />
                                        <span className="ms-2">Outros bancos</span>
                                    </div> : <></>}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {setBankSelected('itau')}}>
                                    <div className="d-flex p-2" id="itau">
                                        <IconItau />
                                        <span className="ms-2">Itaú</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {setBankSelected('bradesco')}}>
                                    <div className="d-flex p-2" id="bradesco">
                                        <IconBradesco />
                                        <span className="ms-2">Bradesco</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {setBankSelected('outrosbancos')}}>
                                    <div className="d-flex p-2" id="outrosbancos">
                                        <IconDebit3ds />
                                        <span className="ms-2">Outros bancos</span>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}


                        {!componentLogic.idpvD3S ? 
                        <>
                            <div> <Form.Label className="pt-4">Selecione o seu banco</Form.Label></div>
                            <div className='btn-group btn-bank-selected mb-2'>
                                <Button name='itau' value='itau' active={bankSelected === 'itau'} className={`btn-bank  onlyLogo rounded`} onClick={() => setBankSelected('itau')}><IconItau /></Button>
                                <Button name='bradesco' active={bankSelected === 'bradesco'} className={`btn-bank  onlyLogo rounded`} onClick={() => { setBankSelected('bradesco') }}><IconBradesco /></Button>
                                {/* <Button name='Mastercard' active={bankSelected === 'outrosbancos'} className={`btn-bank  onlyLogo rounded`} onClick={() => { setBankSelected('outrosbancos') }}><IconMastercard /></Button>
                                <Button name='Visa' active={bankSelected === 'outrosbancos'} className={`btn-bank  onlyLogo rounded`} onClick={() => { setBankSelected('outrosbancos') }}><IconVisa /></Button> */}
                            </div>
                        </>
                        :
                        <>
                            <div> <p className="pt-4">Bancos disponíveis para pagamento</p></div>
                            <div className="row justify-content-between">
                                <div className="col-auto"><IconItau /></div>
                                <div className="col-auto"><IconBradesco /></div>
                                <div className="col-auto"><IconInter /></div>
                                <div className="col-auto"><IconNubank /></div>
                                <div className="col-auto"><IconC6 /></div>
                                <div className="col-auto"><IconBBrasil /></div>
                            </div>

                            <div className="detail-outrosbancos text-center mt-4">
                                {!showMeDebitCard ? 
                                    <>
                                    <p className="mb-1">Consulte as bandeiras e bancos disponíveis</p>
                                    <button type="button" className="btn btn-link btn-readmore" onClick={() => {setShowMeDebitCard(true)}}>Saiba mais</button>
                                    </>
                                    :
                                    <></>
                                }

                                {showMeDebitCard ? 
                                <div className="list-detail-outrosbancos">
                                    <div className="row align-items-center">
                                        <IconMastercard className="col-auto" />
                                        <div className="col">
                                            <h6>Mastercard</h6>
                                            {/* <p>Banco BMG, Banco de Brasilia, Banco do Brasil, Banco Inter, Banco Original, Caixa, C6 Bank, Itaú, Nubank, Cartão online Santander e Sicred</p> */}
                                            <p>Banco do Brasil, Banco Inter, C6 Bank, Itaú, Nubank, Cartão online Santander</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row align-items-center">
                                        <IconVisa className="col-auto" />
                                        <div className="ms-2 col">
                                            <h6>Visa</h6>
                                            {/* <p>Banco do Brasil, Bradesco, Caixa, Itaú, Cartão online Santander e Sicred</p> */}
                                            <p>Banco do Brasil, Bradesco, Itaú, Cartão online Santander</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row align-items-center">
                                        <IconElo className="col-auto" />
                                        <div className="ms-2 col">
                                            <h6>Elo</h6>
                                            {/* <p>Banco do Brasil, Bradesco e Caixa</p> */}
                                            <p>Banco do Brasil, Bradesco</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <button type="button" className="btn btn-link btn-readmore" onClick={() => {setShowMeDebitCard(false)}}>Ok entendi</button>
                                </div>
                                :
                                <></>
                                }
                            </div>
                        </>
                        }
                        </Form.Group>

                        {cartao()}
                    </Accordion.Body>
                </Accordion.Item>
                {!getIdpv().has && getCookie('_idpv') === null && dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '12/13/2023' : '12/20/2023'}`, '12/28/2023').insidePeriod ? 
                    <Accordion.Item eventKey="pix" className="mb-3">
                        <Accordion.Header className='payment-accordion'>
                            <div id="cardPayment" className="card-payment-method-accordion" onClick={() => { componentLogic.setMethod('pix'); props.desabilitarEndereco() }} style={{ borderColor: componentLogic.mostrarCredito === true && componentLogic.mostrarDebito === false ? "#C4C4C4" : "#F2F2F2" }}>
                                <IconPix className="me-3" fill={props.configuration && props.configuration.theme ? props.configuration.theme!.primary : ''} />
                                Pix
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='accordion-body-card mb-3'>
                            {cartao()}
                        </Accordion.Body>
                    </Accordion.Item>
                    :
                    <></>
                }
            </Accordion >
            <p>

                <small className={`text-muted text-center ${componentLogic.valorMensalidade! > 0 ? '' : 'shimmer'}`}>
                    * Pagamento mensal de R$ {doubleToString(componentLogic.valorMensalidade)}.<br />
                    ** O pagamento inicial será de R$ {doubleToString(componentLogic.pagamentoInicial)}, pois inclui a taxa de adesão no valor de R$ {doubleToString(componentLogic.valorAdesao)} mais a primeira mensalidade no valor de R$ {(doubleToString(componentLogic.valorMensalidade))}.<br />
                    ***Valor sujeito a alteração devido ao uso de voucher.
                </small>
            </p>
            {window.innerWidth < 1199.98 ?
                <Row className="mt-4 mb-3 pt-2">
                    <Col sm={12}>
                        <div className="text-center" dangerouslySetInnerHTML={{ __html: props.configuration.helpTextOthers! }}></div>
                    </Col>
                </Row>
                :
                <></>
            }

            <ModalLoading3DS
                show={componentLogic.modalLoading3DS}
                percentLoading={componentLogic.percentLoading}
                onHide={() => componentLogic.setModalLoading3DS(false)}
            />

            <ModalAvisoPixComponent
                show={componentLogic.modalAvisoPixShow}
                onHide={() => componentLogic.setModalAvisoPixShow(false)}
                handleSubmit={componentLogic.handleSubmitModalPix}
            />
        </>
    )
}