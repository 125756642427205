import { CheckoutDTO } from "../dto";
import axiosInstance from "../utils/axiosInstance";

export const LeadService = () => {
    const sendLead = async (data: CheckoutDTO) => {
        if(getParam("utm_source=").value?.toLocaleLowerCase() === "bet7k") {
            data.origem_do_lead = "Bet7k";
        }
        
        try {
            await axiosInstance.post(`/lead`, JSON.stringify(data))
        } catch (error) {
            return undefined;
        }
    };

    return { sendLead };
}