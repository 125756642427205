import { CepService, CpfService, EmailService, FiliacaoService, LeadService, ServicoService, MarketingConversionService, ThreeDsService, cashbackService } from "../../servicos";
import { getCardType, getCookie, removeMask, validarCPF, varlidarEmail, setEventPageView, createCookie, dateComparePeriod, getLeadNome, getLeadEmail, getLeadTelefone, hash, getFbPixelDebug, getLeadUp18, getLeadAcceptTerms, getLeadAcceptNotifications, getCampanhaPublicitaria, acceptIDPVsRefiliacaoPiloto, getIdpv, getconvite } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Cep, Configuration, Cpf } from "../../modelos";
import { CheckoutDTO, StepDTO } from "../../dto";
import React, { useEffect, useState, useContext } from "react";
import { CheckoutContext } from "../../context/checkout.provider";
import { AnalyticsService } from "../../servicos/analyticsService";
import { KwankoService } from "../../servicos/kwanko.service";
import { ThreeDSModelRefund } from "../../servicos/threeDS.service";
import { DataLayerBeginCheckout, DataLayerCepUnavailable, DataLayerError, DatalayerPurchase, DataLayerRefiliacao, DatalayerShippingInfo, } from "../../utils/datalayer";

export const Logic = (test: boolean = false) => {
    const location = test ? null : useLocation();
    const [configuration, alterarConfiguration] = useState(location != null && location.state !== null && (location.state as any).configuration !== null && (location.state as any).configuration !== undefined ? (location.state as any).configuration : new Configuration());
    const {
        setNome,
        setCpf,
        setEmail,
        setTelefone,
        setCep,
        setRua,
        setNumero,
        setBairro,
        setCidade,
        setEstado,
        setTermos,
        setMaior_dezoito,
        setTermosSmsEmail,
        adesao,
        setAdesao,
        mensalidade,
        setMensalidade,
        total,
        setTotal,
        methodSelected,
        voucher: VoucherTratado,
        URLCurrent,
        setURLCurrent,
        utm_Source,
        setUtm_Source,
        utm_Campaign,
        setUtm_Campaign,
        utm_Content,
        setUtm_Content,
        utm_Medium,
        setUtm_Medium,
        utm_Term,
        setUtm_Term,
        paramsURL,
        setParamsURL,
        checkoutValueCDT,
        setCheckoutValueCDT,
        setFranquiaId,
        cashbackEnergiadetodos,
        setCashbackEnergiadetodos,
        isRefiliacao, setIsRefiliacao
    } = useContext(CheckoutContext);

    if (location === null || location.state === null) {
        window.location.replace("/" + window.location.search);
    }

    const navigate = test ? null : useNavigate();
    const [habilitadoPessoa, alterarHabilitadoPessoa] = useState(true);
    const [habilitadoEndereco, alterarHabilitadoEndereco] = useState(true);
    const [posicaoAccordion, alterarPosicaoAccordion] = useState('0');
    const [modalShow, setModalShow] = useState(false);
    const [modalRefiliacaoBlockShow, setModalRefiliacaoBlockShow] = useState(false);
    const [modalRefiliacaoShow, setModalRefiliacaoShow] = useState(false);
    const [modalRefiliacaoMessageShow, setModalRefiliacaoMessageShow] = useState(false);
    const [modalRefiliacaoCepShow, setModalRefiliacaoCepShow] = useState(false);


    const [voucher, alterarVoucher] = useState("");
    const [voucherDescription, alterarVoucherDescription] = useState("<small class='text-muted'>A <b>taxa de adesão do cartão</b> tem o valor de R$29,70 e poderá ser dividida em 2x.</small>");
    const [voucherInvalido, alterarVoucherInvalido] = useState(true);
    const [mensalidadeComp, alterarMensalidadeComp] = useState(0);
    const [adesaoComp, alterarAdesaoComp] = useState(0);
    const [descontoMensalidade, alterarDescontoMensalidade] = useState(0);
    const [descontoAdesao, alterarDescontoAdesao] = useState(0);

    const [franquiaId, alterarFranquiaId] = useState<number>(0);
    const [franquiaNome, alterarFranquiaNome] = useState('');
    const [franquiaRefiliacao, setIdFranquiaRefiliacao] = useState<number>(0);
    const [idFiliadoRefiliacao, setIdFiliadoRefiliacao] = useState<number>(0);
    const [refiliacaoValida, setRefiliacaoValida] = useState<boolean>(false);
    const [userRefiliacao, setUserRefiliacao] = useState<any>({});

    const [loading, alterarLoading] = useState(false);

    const [canFocusCep, alterarCanFocusCep] = useState(false);
    const [canFocusNumero, alterarCanFocusNumero] = useState(false);

    const [loadingCep, alterarLoadingCep] = useState(false);

    const recaptchaRef = React.useRef<any>();

    const [valor, alterarValor] = useState(0);

    const [processoPagamento, alterarProcessoPagamento] = useState(false);

    const [isCepSergipe, setIsCepSergipe] = useState(false);
    const [showModalSergipe, setShowModalSergipe] = useState(false);
    const [mgmData, setMgmData] = useState<any>({});

    useEffect(() => {
        const url = new URL(window.location.href);
        const queryParams = url.searchParams;
        const params = window.location.search;

        const utmSource = queryParams.get('utm_source');
        const utmCampaign = queryParams.get('utm_campaign');
        const utmContent = queryParams.get('utm_content');
        const utmMedium = queryParams.get('utm_medium');
        const utmTerm = queryParams.get('utm_term');

        if (utmSource) setUtm_Source(utmSource);
        if (utmCampaign) setUtm_Campaign(utmCampaign);
        if (utmContent) setUtm_Content(utmContent);
        if (utmMedium) setUtm_Medium(utmMedium);
        if (utmTerm) setUtm_Term(utmTerm);

        setParamsURL(params);

        if (getParam("utm_source=").value?.toLocaleLowerCase() === "kwanko") {
            //Kwanko Product
            const script = document.createElement('script');
            script.src = 'https://img.metaffiliation.com/u/24/p77473.js';
            document.head.appendChild(script);

            window.ptag_params = {
                zone: 'product',
                productId: '1',
                categoryId: ''
            };
        }

        if(getconvite().has) {
            getCPFFiliado(getconvite().value);
        }
    }, [])

    async function getCPFFiliado(convite: any) {
        let conviteData = JSON.parse(window.atob(convite));
        let resp: any = await CpfService().getFiliado(conviteData.matricula);
        if (resp !== undefined) {
            console.log('resp', resp);
            setMgmData({
                convite: conviteData.convite,
                cpf: resp.dados.cpf,
            })
        }
    }

    useEffect(() => {
        if (getLeadNome().has)
            alterarNome(decodeURIComponent(getLeadNome().value));
        if (getLeadEmail().has)
            alterarEmail(decodeURIComponent(getLeadEmail().value));
        if (getLeadTelefone().has)
            alterarTelefone(decodeURIComponent(getLeadTelefone().value));
        if (getLeadUp18().has)
            alterarMaiorDezoito(getLeadUp18().value === "true" ? true : false);
        if (getLeadAcceptTerms().has)
            alterarTermos(getLeadAcceptTerms().value === "true" ? true : false);
        if (getLeadAcceptNotifications().has)
            alterarTermosSmsEmail(getLeadAcceptNotifications().value === "true" ? true : false);

        if (mensalidade === 0 && adesao === 0 && total === 0) {


            /*
             * Funcionalidade Voucher com preenchimento automático
             * - Busca valor de serviço para utilização na tela
             * 
             * TODO: Realizar melhoria
            */
            // if (dateComparePeriod(`04/${process.env.REACT_APP_ENVIRONMENT === "dev" ? '18' : '24'}/2023`, "04/24/2023").insidePeriod) {

            ServicoService().buscarValorServico(configuration.linkAfiliado && !configuration.campanha_publicitaria ? 1 : 18).then((valorNormalMensalidade: any) => {
                ServicoService().buscarValorServico(configuration.linkAfiliado && !configuration.campanha_publicitaria ? 4 : 19).then((valorNormalAdesao: any) => {
                    alterarMensalidadeComp(valorNormalMensalidade.valor);
                    alterarAdesaoComp(valorNormalAdesao.valor);
                    alterarValor(valorNormalMensalidade.valor + valorNormalAdesao.valor);

                    setAdesao(valorNormalAdesao.valor);
                    setMensalidade(valorNormalMensalidade.valor);
                    setTotal(valorNormalMensalidade.valor + valorNormalAdesao.valor);

                    configuration.prices.mensalidade = valorNormalMensalidade.valor;
                    configuration.prices.adesao = valorNormalAdesao.valor;

                    alterarConfiguration(configuration);
                });
            });
            // }

            if (configuration.campanha_publicitaria) {
                document.cookie = '_idpv=; Max-Age=0';
                if (configuration.id_promotor_lead) {
                    configuration.id_promotor_lead = '-';
                }
            }
        }

        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);

        document.body.classList.remove('overflow-hidden');
        document.body.classList.add('overflow-auto');

        var count = window.localStorage.getItem("count");
        var localCount = 1;
        if (count) {
            localCount = Number.parseInt(count);
        } else {
            window.localStorage.setItem("count", localCount.toString());
        }

        configuration.count = localCount;

        var _uuid = getCookie('_uuid');
        configuration.ga_codigo = _uuid

        if (window.adyen == null) {
            const scriptAdyenEncrypt = document.createElement("script");
            scriptAdyenEncrypt.src = `${window.location.origin}/adyen.encrypt.min.js`;
            scriptAdyenEncrypt.async = true;
            document.body.appendChild(scriptAdyenEncrypt);

            const scriptAdyenSDK = document.createElement("script");
            scriptAdyenSDK.src = `${window.location.origin}/adyen.sdk.4.7.2.js`;
            scriptAdyenSDK.async = true;
            document.body.appendChild(scriptAdyenSDK);
        }
    }, []);

    useEffect(() => {
        if (franquiaId > 0) {
            getCashbackEDT(franquiaId);
        }
    }, [franquiaId])

    async function getCashbackEDT(franquiaId) {
        const getCashbackEDT: any = await cashbackService().getEnergiaDeTodos(franquiaId);
        setCashbackEnergiadetodos(getCashbackEDT.success);
    }


    function CaptureURLCurrent() {
        var currentURL = window.location.host;
        var firstPartURL = currentURL.split('.')[0];

        switch (firstPartURL) {
            case 'assine':
                setURLCurrent('checkout-assine');
                setCheckoutValueCDT('Assine');
                break;

            case 'solicite':
                setURLCurrent('checkout-solicite');
                setCheckoutValueCDT('Solicite');
                break;

            case 'idpv':
                setURLCurrent('checkout-idpv');
                setCheckoutValueCDT('IDPV');
                break;
        }
    }

    useEffect(() => {
        CaptureURLCurrent();
    }, [])

    useEffect(() => {
        if (total > 0) {
            var payload: any = {
                type: "facebook",
                data: {
                    data: [
                        {
                            event_name: "InitiateCheckout",
                            event_time: Math.floor(Date.now() / 1000),
                            user_data: {
                                client_ip_address: sessionStorage.getItem("_ipv4"),
                                client_user_agent: navigator.userAgent,
                                fbp: getCookie('_fbp')
                            },
                            content_category: 'Filiação Cartão de Todos',
                            content_name: 'CDT Site',
                            currency: 'BRL',
                            num_items: 1,
                            value: total
                        }
                    ]
                }
            }
            if (getFbPixelDebug().has)
                payload.data.test_event_code = getFbPixelDebug().value;

            MarketingConversionService().sendData(payload);
        }
    }, [total])


    const [dataNasc, alterarDataNasc] = useState("");
    const [dataNascValido, alterarDataNascValido] = useState(false);
    const [dataNascInvalido, alterarDataNascInvalido] = useState(false);
    const [dataNascMensagemErro, alterarDataNascMensagemErro] = useState("Campo obrigatório");
    function verificarDataNasc() {
        var valid = false;
        var splitedData = dataNasc.split("/");
        var minAge = true;

        if (dataNasc.length === 10) {
            var date = new Date(`${parseInt(splitedData[2])}-${parseInt(splitedData[1])}-${parseInt(splitedData[0])}`);
            valid = !isNaN(date.getTime());

            if (valid && getAge(date) < 18) {
                valid = false;
                minAge = false;
            }
        }

        alterarDataNascInvalido(!valid);
        alterarDataNascValido(valid);

        if (dataNasc.length <= 0)
            alterarDataNascMensagemErro("Campo obrigatório");
        else if (!minAge)
            alterarDataNascMensagemErro("Idade não é maior de 18 anos");
        else
            alterarDataNascMensagemErro("Data inválida");

        // ActionService().sendInvalidField(!valid, dataNascMensagemErro, dataNasc, "dataNasc", configuration.ga_codigo!);
    }

    function getAge(birthDate: Date) {
        var today = new Date();
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const [maiorDezoito, alterarMaiorDezoito] = useState(false);
    const [maiorDezoitoInvalido, alterarMaiorDezoitoInvalido] = useState(false);
    const [maiorDezoitoMensagemErro, alterarMaiorDezoitoMensagemErro] = useState("Campo obrigatório");
    function verificarMaiorDezoito() {
        alterarMaiorDezoitoInvalido(maiorDezoito === false);
        if (!maiorDezoito)
            alterarMaiorDezoitoMensagemErro("Campo obrigatório");

        // ActionService().sendInvalidField(maiorDezoito === false, maiorDezoitoMensagemErro, maiorDezoito.toString(), "maiorDezoito", configuration.ga_codigo!);
    }
    useEffect(() => {
        if (maiorDezoito)
            verificarMaiorDezoito();
    }, [maiorDezoito])

    const [nome, alterarNome] = useState("");
    const [nomeValido, alterarNomeValido] = useState(false);
    const [nomeInvalido, alterarNomeInvalido] = useState(false);
    const [nomeMensagemErro, alterarNomeMensagemErro] = useState("Campo obrigatório");
    function verificarNome() {
        var hasNumber = /\d/;
        // var nameRegex = /^(?:[A-Za-z\u00C0-\u017F]+\s){1,5}[A-Za-z\u00C0-\u017F]+$/u;
        // const nameRegex = /^\s?(?:[A-Za-z\u00C0-\u017F]+\s+){1,5}[A-Za-z\u00C0-\u017F]+\s*$/u;
        {/* @ts-ignore: Unreachable code error */}
        const nameRegex = /^\s?(?:[A-Za-z\u00C0-\u017F]+\s+){1,5}[A-Za-z\u00C0-\u017F]+\.?\s*$/u;

        var message = nomeMensagemErro;
        var invalid = nome.length < 3 || hasNumber.test(nome) || !nameRegex.test(nome);

        alterarNomeInvalido(invalid);
        alterarNomeValido(nome.length >= 3 && !hasNumber.test(nome) && nameRegex.test(nome));

        if (nome.length <= 0)
            message = "Campo obrigatório";
        else if (hasNumber.test(nome)) {
            message = "Nome inválido";
            alterarNomeInvalido(true);
            alterarNomeValido(false);
        }
        else
            message = "Digite seu nome completo";


        alterarNomeMensagemErro(message);

        // ActionService().sendInvalidField(invalid, message, nome, "nome", configuration.ga_codigo!);
    }

    async function KwankoListing() {
        //Kwanko Listing
        const script = document.createElement('script');
        script.src = 'https://img.metaffiliation.com/u/24/p77473.js';
        document.head.appendChild(script);

        window.ptag_params = {
            zone: 'listing',
            categoryId: '',
            products: ["1"]
        };
    }

    const [cpf, alterarCpf] = useState("");
    const [cpfValido, alterarCpfValido] = useState(false);
    const [cpfInvalido, alterarCpfInvalido] = useState(false);
    const [cpfMensagemErro, alterarCpfMensagemErro] = useState("Campo obrigatório");
    // const [validCpfCTN, setValidCpfCTN] = useState(false);
    const [cpfCadastradoCDT, setCpfCadastradoCDT] = useState(false);

    function verificarCpf() {
        // setValidCpfCTN(false);
        console.log('verificarCpf');
        
        var cpfTratado = removeMask(cpf);
        var eValido: boolean = validarCPF(cpfTratado);
        var message = cpfMensagemErro;

        alterarCpfInvalido(!eValido);
        alterarCpfValido(eValido);
        
        if (cpfTratado.length <= 0)
            alterarCpfMensagemErro("Campo obrigatório");
        
        if (cpf !== cpfTratado) {
            alterarCpf(cpfTratado);    
            if (eValido) {
                buscarCpf(cpfTratado);
            }
            else {
                message = 'CPF inválido';
                alterarCpfMensagemErro(message);
                // setValidCpfCTN(false);
            }
        }



        // ActionService().sendInvalidField(!eValido, message, cpfTratado, "cpf", configuration.ga_codigo!);
    }

    const buscarCpf = async (cpfTratado: string) => {
        // setValidCpfCTN(false);
        var resp: Cpf | any = await CpfService().validarCpf(cpfTratado);

        if (getParam("utm_source=").value?.toLocaleLowerCase() === "kwanko") {
            KwankoListing();
        }

        if (resp !== undefined && !resp.cadastrado) {
            setCpfCadastradoCDT(false);
            setModalRefiliacaoShow(false);
            setModalRefiliacaoMessageShow(false);
        }
        else if (resp.cadastrado && resp.aceitaRefiliar) {
            setIsRefiliacao(true);
            setCpfCadastradoCDT(true);
            setModalRefiliacaoShow(true);
            setModalRefiliacaoMessageShow(true);
            setIdFranquiaRefiliacao(resp.data.idFranquia);
            setIdFiliadoRefiliacao(resp.data.idFiliado);
            setUserRefiliacao(resp);
            // if(acceptIDPVsRefiliacaoPiloto(getIdpv().value)) {
            // }
            // else {
            //     setModalRefiliacaoBlockShow(true);
            // }
        }
        else {
            alterarCpfInvalido(true);
            alterarCpfValido(false);
            alterarCpfMensagemErro("CPF já cadastrado");
            if (resp && resp.cadastrado === false) {
                setCpfCadastradoCDT(false);
            }
        }
    }

    const [email, alterarEmail] = useState("");
    const [emailAnterior, alterarEmailAnterior] = useState("");
    const [emailValido, alterarEmailValido] = useState(false);
    const [emailInvalido, alterarEmailInvalido] = useState(false);
    const [emailMensagemErro, alterarEmailMensagemErro] = useState("Campo obrigatório");

    const [validEmailCTN, setValidEmailCTN] = useState(false);

    async function verificarEmail() {
        var eValido: boolean = varlidarEmail(email);
        alterarEmailInvalido((value) => value = !eValido);
        alterarEmailValido((value) => value = eValido);
        var message = emailMensagemErro;

        if (emailAnterior === "" || emailAnterior !== email) {
            setValidEmailCTN(false);

            if (email.length <= 0) {
                alterarEmailMensagemErro("Campo obrigatório");
                alterarHabilitadoEndereco(false);
            }
            else {

                if (!eValido) {
                    message = "E-mail inválido";
                    alterarEmailMensagemErro(message);
                    alterarHabilitadoEndereco(false);
                }
                else if (eValido) {
                    var emailResp: any = await EmailService().validarEmail(email);

                    if (emailResp.idpv && emailResp.idpv !== '-') {
                        if (configuration.campanha_publicitaria) {
                            configuration.id_promotor_lead = '-';
                        }
                        else {
                            configuration.id_promotor_lead = getCookie('_idpv') ? getCookie('_idpv') : emailResp.idpv;
                            configuration.linkAfiliado = true;
                            if (!getCookie('_idpv')) {
                                createCookie('_idpv', emailResp.idpv, 14);
                            }
                        }
                        alterarConfiguration(configuration);
                    }

                    var validoCadastro = emailResp.data ?? true;

                    if (!validoCadastro && !cpfCadastradoCDT) {
                        message = "E-mail já cadastrado";
                        alterarEmailMensagemErro(message);
                        alterarEmailInvalido(true);
                        alterarEmailValido(false);
                        eValido = false;
                        alterarHabilitadoEndereco(false);
                    } else {
                        alterarHabilitadoEndereco(true);
                        setValidEmailCTN(true);
                        alterarEmailAnterior(email);
                    }
                }
            }
        }

        // ActionService().sendInvalidField(!eValido, message, email, "email", configuration.ga_codigo!);
    }

    const [telefone, alterarTelefone] = useState("");
    const [telefoneValido, alterarTelefoneValido] = useState(false);
    const [telefoneInvalido, alterarTelefoneInvalido] = useState(false);
    const [telefoneMensagemErro, alterarTelefoneMensagemErro] = useState("Campo obrigatório");
    function verificarTelefone() {
        var telefoneTratado = removeMask(telefone);
        var messasge = telefoneMensagemErro;

        alterarTelefone(telefoneTratado);
        alterarTelefoneInvalido(telefoneTratado.length < 11);
        alterarTelefoneValido(telefoneTratado.length >= 11);

        if (telefoneTratado.length <= 0) {
            messasge = "Campo obrigatório";
            alterarTelefoneMensagemErro(messasge);
        }
        else {
            messasge = "Telefone inválido";
            alterarTelefoneMensagemErro(messasge);
        }

        // ActionService().sendInvalidField(telefoneTratado.length < 11, messasge, telefoneTratado, "telefone", configuration.ga_codigo!);
    }

    const [termos, alterarTermos] = useState(false);
    const [termosSmsEmail, alterarTermosSmsEmail] = useState(false);
    const [termosInvalido, alterarTermosInvalido] = useState(false);
    const [termosMensagemErro, alterarTermosMensagemErro] = useState("Campo obrigatório");

    function verificarTermos() {
        alterarTermosInvalido(termos === false);
        if (!termos)
            alterarTermosMensagemErro("Campo obrigatório");

        // ActionService().sendInvalidField(termos === false, termosMensagemErro, termos.toString(), "termos", configuration.ga_codigo!);
    }
    useEffect(() => {
        if (termos)
            verificarTermos();
    }, [termos]);

    const [cep, alterarCep] = useState("");
    const [cepValido, alterarCepValido] = useState(false);
    const [cepInvalido, alterarCepInvalido] = useState(false);
    const [cepMensagemErro, alterarCepMensagemErro] = useState("Campo obrigatório");
    const [respCep, alterarRespCep]: Cep | any = useState({});
    async function verificarCep(verifyCepOnServer: boolean = true) {
        var cepTratado = removeMask(cep);
        var message = cepMensagemErro;
        var invalid = cepInvalido;
        alterarCanFocusNumero(true);

        if (cepTratado.length <= 0) {
            invalid = true;
            alterarCepInvalido(true);
            alterarCepValido(false);
            
            alterarCepMensagemErro("Campo obrigatório");
            return;
        } 
        else if(cepTratado.length < 8) {
            invalid = true;
            alterarCepInvalido(true);
            alterarCepValido(false);
            
            alterarCepMensagemErro("CEP incompleto");
            return;
        }
        else {
            if (verifyCepOnServer) {
                alterarLoadingCep(true);
                invalid = await buscarCep(cepTratado, invalid);
                alterarLoadingCep(false);
            }
        }

        // ActionService().sendInvalidField(invalid, message, cepTratado, "cep", configuration.ga_codigo!);
    }

    const [rua, alterarRua] = useState("");
    const [ruaValido, alterarRuaValido] = useState(false);
    const [ruaInvalido, alterarRuaInvalido] = useState(false);
    const [ruaMensagemErro, alterarRuaMensagemErro] = useState("Campo obrigatório");
    function verificarRua(value: string | undefined = undefined) {
        alterarRuaInvalido(value !== undefined ? value.length < 1 : rua.length < 1);
        alterarRuaValido(value !== undefined ? value.length >= 1 : rua.length >= 1);

        if (value !== undefined ? value.length <= 0 : rua.length <= 0)
            alterarRuaMensagemErro("Campo obrigatório");

        // ActionService().sendInvalidField(value !== undefined ? value.length < 1 : rua.length < 1, ruaMensagemErro, rua, "rua", configuration.ga_codigo!);
    }

    const [bairro, alterarBairro] = useState("");
    const [bairroValido, alterarBairroValido] = useState(false);
    const [bairroInvalido, alterarBairroInvalido] = useState(false);
    const [bairroMensagemErro, alterarBairroMensagemErro] = useState("Campo obrigatório");
    function verificarBairro(value: string | undefined = undefined) {
        alterarBairroInvalido(value !== undefined ? value.length < 1 : bairro.length < 1);
        alterarBairroValido(value !== undefined ? value.length >= 1 : bairro.length >= 1);

        if (value !== undefined ? value.length <= 0 : bairro.length <= 0)
            alterarBairroMensagemErro("Campo obrigatório");
    }

    const [numero, alterarNumero] = useState("");
    const [numeroValido, alterarNumeroValido] = useState(false);
    const [numeroInvalido, alterarNumeroInvalido] = useState(false);
    const [numeroMensagemErro, alterarNumeroMensagemErro] = useState("Campo obrigatório");
    function verificarNumero() {
        var numeroTratado = removeMask(numero);

        alterarNumero(numeroTratado);
        alterarNumeroInvalido(numeroTratado.length < 1);
        alterarNumeroValido(numeroTratado.length >= 1);

        if (numeroTratado.length <= 0)
            alterarNumeroMensagemErro("Campo obrigatório");

        // ActionService().sendInvalidField(numeroTratado.length < 1, numeroMensagemErro, numeroTratado, "numero", configuration.ga_codigo!);
    }

    const [complemento, alterarComplemento] = useState("");
    const [cidade, alterarCidade] = useState("");
    const [estado, alterarEstado] = useState("");

    const buscarCep = async (cepTratado: string, invalid: boolean) => {
        setCashbackEnergiadetodos(false);
        alterarCep(cepTratado);
        var resp: Cep | any = await CepService().buscarCep(cepTratado, getForm());     

        invalid = resp !== undefined && resp?.message !== undefined;

        alterarCepValido(resp !== undefined && resp?.message === undefined);
        alterarCepInvalido(resp !== undefined && resp?.message !== undefined);

        if (resp !== undefined && resp?.message === undefined) {
            alterarRua(resp.logradouro);
            verificarRua(resp.logradouro);
            alterarBairro(resp.bairro);
            verificarBairro(resp.bairro);
            setFranquiaId(resp.franquiaId);

            if (franquiaRefiliacao !== 0 && franquiaRefiliacao !== resp.franquiaId) {
                setModalRefiliacaoCepShow(true);
                alterarHabilitadoPessoa(false);
                alterarHabilitadoEndereco(false);
            }
        }

        alterarCidade(resp !== undefined && resp?.message === undefined ? resp.cidade : "");
        alterarEstado(resp !== undefined && resp?.message === undefined ? resp.uf : "");
        alterarFranquiaId(resp !== undefined && resp?.message === undefined ? resp.franquiaId : 0);
        alterarFranquiaNome(resp !== undefined && resp?.message === undefined ? resp.franquiaNome : "");
        alterarRespCep(resp);

        if (resp !== undefined && resp?.message !== undefined) {
            alterarCepMensagemErro(resp.message);
        }


        if (resp.uf === 'SE') {
            setIsCepSergipe(true);
        }

        AnalyticsService().SendAnalytics(
            {
                event: "CepInserted",
                channel: URLCurrent,
                parameters: `{cep: ${cep ? cep : null}, status: ${resp.franquiaId ? 'sucesso' : 'erro'}}`,
                source: utm_Source ? utm_Source : null,
                campaign: utm_Campaign ? utm_Campaign : null,
                idpv: idpv ? idpv : null,
                url: window.location.origin,
                lead: {
                    name: nome,
                    email: email,
                }
            }
        );


        DatalayerShippingInfo({
            cep: cepTratado,
            price: configuration.prices.mensalidade + configuration.prices.adesao,
            idpv: idpv
        })

        if(resp.status === 400) {
            DataLayerCepUnavailable();

            AnalyticsService().SendInvalidCep(
                {
                    cep,
                    cpf,
                    name: nome,
                    email,
                    phone: telefone,
                    acceptTerms: termos,
                    optin18: maiorDezoito,
                    optinContact: termosSmsEmail
                }
            );
        }

        if (resp !== undefined && resp?.message !== undefined)
            return resp.message;
    }

    const enderecoAccordion = document.getElementById("enderecoAccordion")?.getElementsByClassName("accordion-button")[0];
    const pagamentoAccordion = document.getElementById("pagamentoAccordion")?.getElementsByClassName("accordion-button")[0];
    enderecoAccordion?.setAttribute("disabled", "disabled");
    pagamentoAccordion?.setAttribute("disabled", "disabled");

    async function desabilitarPessoa() {
        if (nomeValido && cpfValido && emailValido && telefoneValido && (termos && !termosInvalido) && (maiorDezoito && !maiorDezoitoInvalido)) {
            setNome(nome);
            setCpf(cpf);
            setEmail(email);
            setTelefone(telefone);
            setTermos(termos);
            setMaior_dezoito(maiorDezoito);
            setTermosSmsEmail(termosSmsEmail);

            if (habilitadoPessoa) {
                var dto = new StepDTO();

                dto.user = configuration.ga_codigo;
                dto.data = {
                    form: getForm()
                }
                // ActionService().sendStep(dto, 1);
            }

            alterarHabilitadoPessoa(false);
            alterarPosicaoAccordion('1');
            const pessoa = document.getElementById("pessoaAccordion")!.getElementsByClassName("accordion-button")[0];

            setTimeout(() => {
                if (!cepValido && !ruaValido && !bairroValido && !numeroValido) {
                    enderecoAccordion?.removeAttribute("disabled");
                }
                pessoa.setAttribute("disabled", "disabled");
                pessoa.classList.add("validated");
            }, 0);

            if (posicaoAccordion === '0') {

                DataLayerBeginCheckout({
                    idpv: idpv,
                    optInSms: termosSmsEmail,
                    formPhone: telefone,
                    formEmail: email,
                    price: configuration.prices.mensalidade + configuration.prices.adesao,
                });

            }

            if (getParam("utm_source=").value?.toLocaleLowerCase() === "kwanko") {
                //Kwanko Basket
                const script = document.createElement('script');
                script.src = 'https://img.metaffiliation.com/u/24/p77473.js';
                document.head.appendChild(script);

                window.ptag_params = {
                    zone: 'basket',
                    products: [{ id: "1", price: total, quantity: '1' },],
                    currency: 'BRL'
                };
            }

            AnalyticsService().SendAnalytics(
                {
                    event: "GenerateLead",
                    channel: URLCurrent,
                    parameters: paramsURL ? paramsURL : null,
                    source: utm_Source ? utm_Source : null,
                    campaign: utm_Campaign ? utm_Campaign : null,
                    idpv: idpv ? idpv : null,
                    url: window.location.origin,
                    lead: {
                        name: nome,
                        email: email,
                        phone: telefone,
                        cpf: cpf,
                        optin18: maiorDezoito,
                        optinContact: termosSmsEmail,
                        acceptTerms: termos
                    }
                }
            );


            // Facebook Conversion API -- Event Lead
            {/* @ts-ignore: Unreachable code error */}
            var explodeName = nome.split(/ (.*)/s);

            if (explodeName[1] !== undefined) {

                const fn = await hash(explodeName[0].toLocaleLowerCase());
                const ln = await hash(explodeName[1].toLocaleLowerCase());
                const em = await hash(email.toLocaleLowerCase());
                const ph = await hash(telefone);

                var payload: any = {
                    type: "facebook",
                    data: {
                        data: [
                            {
                                event_name: "Lead",
                                event_time: Math.floor(Date.now() / 1000),
                                user_data: {
                                    fn: fn,
                                    ln: ln,
                                    em: em,
                                    ph: ph,
                                    client_ip_address: sessionStorage.getItem("_ipv4"),
                                    client_user_agent: navigator.userAgent,
                                    fbp: getCookie('_fbp')
                                },
                            }
                        ]
                    }
                }
                if (getFbPixelDebug().has)
                    payload.data.test_event_code = getFbPixelDebug().value;

                MarketingConversionService().sendData(payload);
            }



        }
        else {
            verificarNome();
            verificarEmail();
            verificarCpf();
            verificarTelefone();
            verificarMaiorDezoito();
            verificarTermos();
        }
    }

    async function desabilitarEndereco() {

        if (cepValido && ruaValido && bairroValido && numeroValido) {
            setCep(cep);
            setRua(rua);
            setNumero(numero);
            setBairro(bairro);
            setCidade(cidade);
            setEstado(estado);

            if (habilitadoEndereco) {
                var dto = new StepDTO();

                dto.user = configuration.ga_codigo;
                dto.data = {
                    form: getForm()
                }
                // ActionService().sendStep(dto, 2);
            }

            alterarHabilitadoEndereco(false);
            desabilitarPessoa();
            alterarPosicaoAccordion('2');

            setTimeout(() => {
                pagamentoAccordion?.removeAttribute("disabled");
                enderecoAccordion?.classList.add("validated");
                enderecoAccordion?.setAttribute("disabled", "disabled");
            }, 0);


            AnalyticsService().SendAnalytics(
                {
                    event: "LeadAddress",
                    channel: URLCurrent,
                    parameters: `{id: ${respCep.franquiaId ? respCep.franquiaId : null}, franchise: ${respCep.franquiaNome}}`,
                    source: utm_Source ? utm_Source : null,
                    campaign: utm_Campaign ? utm_Campaign : null,
                    idpv: idpv ? idpv : null,
                    url: window.location.origin,
                    lead: {
                        name: nome,
                        email: email,
                    }
                }
            );
        }
    }


    function getForm() {
        const refuturizaStorage = window.localStorage.getItem("refuturiza");
        const refuturizaParse = JSON.parse(refuturizaStorage as any);
        const cashbackEDTStorage = window.localStorage.getItem("cashbackedt");
        const cashbackEDTParse = JSON.parse(cashbackEDTStorage as any);

        return {
            nome,
            cpf,
            email,
            telefone,
            termos,
            termosSmsEmail,
            cep,
            franquiaId,
            franquiaNome,
            rua,
            numero,
            complemento,
            bairro,
            cidade,
            estado,
            idFiliadoRefiliacao,
            validFiliadoRefiliacao: refiliacaoValida,
            voucher: voucherInvalido ? null : voucher,
            linkAfiliado: configuration.linkAfiliado,
            id_promotor_lead: configuration.id_promotor_lead ? configuration.id_promotor_lead : '',
            tipo_de_vendedor: configuration.linkAwin ? 'Afiliado' : undefined,
            campanha_publicitaria: getCampanhaPublicitaria().value,
            ga_codigo: configuration.ga_codigo,
            data_nascimento: convertDataNasc(dataNasc),
            maior_dezoito: maiorDezoito,
            addRefuturiza: refuturizaParse,
            checkout_cdt: checkoutValueCDT,
            lead_refiliacao: cpfCadastradoCDT,
            utm_campaign: utm_Campaign,
            utm_content: utm_Content,
            utm_medium: utm_Medium,
            utm_source: utm_Source,
            utm_term: utm_Term,
            optin_energiadetodos: cashbackEDTParse,
            convite: getconvite().has ? mgmData.convite : '',
            codigo_da_indicacao: getconvite().has ? mgmData.convite : '',
            cpf_indicador: getconvite().has ? mgmData.cpf : '',
            etapa_voucher: voucherInvalido ? null : voucher
        } as CheckoutDTO;
    }

    function convertDataNasc(dataNasc: string) {
        if (dataNasc.length > 0 && dataNasc.length === 10) {
            var data = dataNasc.split("/");
            return `${data[2]}-${data[1]}-${data[0]}`;
        }
        else
            return dataNasc;
    }

    async function sendOptIn(value: boolean) {
        if (value) {
            var form = getForm();
            form.termosSmsEmail = value;
        }
    }

    useEffect(() => {
        if (cpf) {
            verificarCpf();
        }
        if (termos && !termosInvalido) {
            verificarNome();
            verificarCpf();
            verificarEmail();
            verificarTelefone();
            verificarTermos();
            verificarMaiorDezoito();
        }
    }, [termos, termosInvalido])

    async function handleSubmit(event: any, formCartao: any, captchaToken: any = '', form?: any) {
        var success = false;
        event?.preventDefault();
        event?.stopPropagation();

        if (email === "")
            verificarEmail();

        verificarNome();
        verificarCpf();
        verificarTelefone();
        verificarTermos();
        verificarCep(false);
        verificarRua();
        verificarNumero();

        if (configuration.isTestAB)
            verificarDataNasc();
        else
            verificarMaiorDezoito();

        if ((nomeValido && cpfValido && emailValido && telefoneValido && termos && cepValido && ruaValido && bairroValido && numeroValido && formCartao !== undefined && (configuration.isTestAB ? dataNascValido : maiorDezoito)) || form) {
            alterarLoading(true);

            var dto = form ? form : getForm() as CheckoutDTO;
            dto.linkAfiliado = configuration.linkAfiliado;

            if (formCartao.method !== 'pix') {

                dto.pagamento = {
                    numeroCartao: formCartao.number,
                    nomeCartao: formCartao.holderName,
                    codigoSeguranca: formCartao.cvc,
                    ano: Number.parseInt(`${new Date().getFullYear().toString().substring(0, 2)}${formCartao.expiryYear}`),
                    mes: Number.parseInt(formCartao.expiryMonth),
                    criptografiaAdyen: formCartao.cryptData,
                    bandeira: getCardType(formCartao.number),
                    total: total,
                    bankName: formCartao.bankName,
                    method: formCartao.method,
                };

                /**
                 * NEW 3DS
                 */
                if(formCartao.bankName === "outrosbancos") {
                    dto.browser_info = {
                        acceptHeader: "*/*",
                        colorDepth: window.screen.colorDepth,
                        language: navigator.language,
                        javaEnabled: false,
                        screenHeight: window.innerHeight,
                        screenWidth: window.innerWidth,
                        userAgent: navigator.userAgent,
                        timeZoneOffset: new Date().getTimezoneOffset()
                    }
                }

                /**
                 * OLD 3DS
                 */
                // if (formCartao.bankName === "outrosbancos") {
                //     dto.pagamento.referencia = formCartao.referencia;
                //     dto.pagamento.threeDSPayment = true;
                //     dto.pagamento.cardid = [formCartao.threeDSCardId, formCartao.threeDSCardId2];
                //     dto.pagamento.threeDSTiD = [formCartao.threeDSTiD1, formCartao.threeDSTiD2];
                //     dto.pagamento.threeDSFingerPrint = formCartao.threeDSFingerPrint;
                //     dto.pagamento.adesao = adesao;
                //     dto.pagamento.mensalidade = mensalidade;

                //     if (dto.pagamento.cardid[0] === "" || dto.pagamento.cardid[1] === "") {
                //         // var refundData = new ThreeDSModelRefund();
                //         // if(dto.pagamento.cardid[0] !== "") {
                //         //     refundData = {
                //         //         cpf: dto.cpf,
                //         //         gateway: "Adyen",
                //         //         tid: dto.pagamento.threeDSTiD[0]
                //         //     }
                //         //     const refund = await ThreeDsService().get3DS2Refund(refundData);
                //         //     console.warn(refund);
                //         // }
                //         // else if(dto.pagamento.cardid[1] !== "") {
                //         //     refundData = {
                //         //         cpf: dto.cpf,
                //         //         gateway: "Adyen",
                //         //         tid: dto.pagamento.threeDSTiD[1]
                //         //     }
                //         //     const refund = await ThreeDsService().get3DS2Refund(refundData);
                //         //     console.warn(refund);
                //         // }

                //         if (navigate != null) return navigate("/analise" + window.location.search, { state: { configuration, dto, total: total } });
                //     }
                // }
            }
            else {
                if (formCartao.numeroWhatsApp !== '') {
                    dto.telefone = formCartao.numeroWhatsApp;
                }
                dto.pagamento = {
                    total: total,
                    method: formCartao.method,
                };
            }

            var validRefiliacao: any;
            if (idFiliadoRefiliacao !== 0) {
                validRefiliacao = await FiliacaoService().sendValidaReFiliacao(dto) as any;
            }

            if (captchaToken === '') {
                captchaToken = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'checkout' });;
            }

            if (captchaToken) {
                alterarProcessoPagamento(true);
                var resp: any;
                if (idFiliadoRefiliacao !== 0 && validRefiliacao && validRefiliacao.Success === true) {
                    dto.validFiliadoRefiliacao = true;
                    var respRef = await FiliacaoService().sendReFiliacao(dto, captchaToken) as any;

                    if (respRef !== undefined && respRef.Success) {
                        resp = {
                            success: respRef.Success,
                            message: 'Refiliação realizada com sucesso.',
                            data: {
                                tid: respRef.Data.Tid,
                                matricula: respRef.Data.Matricula,
                                idFiliado: userRefiliacao.data.idFiliado,
                            }
                        }
                    }
                    else {
                        resp = {
                            success: false,
                            message: 'Erro ao processar o pagamento na refiliação.',
                            respRef: respRef
                        }
                    }
                }
                else if (idFiliadoRefiliacao === 0) {
                    resp = await FiliacaoService().sendFiliacao(dto, captchaToken) as any;
                }


                const refuturizaStorage = window.localStorage.getItem("refuturiza");
                const refuturizaParse = JSON.parse(refuturizaStorage as any);
                const cashbackEDTStorage = window.localStorage.getItem("cashbackedt");
                const cashbackEDTParse = JSON.parse(cashbackEDTStorage as any);

                if (resp !== undefined && resp.success) {
                    success = true;
                    DatalayerPurchase({
                        cpf: dto.cpf,
                        email: dto.email,
                        phone: dto.phone,
                        tid: resp.data.tid,
                        total,
                        method_payment: formCartao.method === 'credito' ? 'credit-card' : formCartao.method === 'debito' ? 'debit' : 'pix',
                        voucher: dto.voucher,
                        price: total,
                        idpv: idpv,
                        isRefuturiza: refuturizaParse,
                        isEnergiaTodos: cashbackEDTParse
                    })


                    // Facebook Conversion API -- Event Purchase
                    {/* @ts-ignore: Unreachable code error */}
                    var explodeName = nome.split(/ (.*)/s);
                    const fn = await hash(explodeName[0].toLocaleLowerCase());
                    const ln = await hash(explodeName[1].toLocaleLowerCase());
                    const em = await hash(email.toLocaleLowerCase());
                    const ph = await hash(telefone);
                    const ct = await hash(cidade.toLocaleLowerCase());
                    const st = await hash(estado.toLocaleLowerCase());
                    const zp = await hash(cep);
                    const country = await hash('br');
                    var payload: any = {
                        type: "facebook",
                        data: {
                            data: [
                                {
                                    event_name: "Purchase",
                                    event_time: Math.floor(Date.now() / 1000),
                                    user_data: {
                                        fn: fn,
                                        ln: ln,
                                        em: em,
                                        ph: ph,
                                        ct: ct,
                                        st: st,
                                        zp: zp,
                                        country: country,
                                        client_ip_address: sessionStorage.getItem("_ipv4"),
                                        client_user_agent: navigator.userAgent,
                                        fbp: getCookie('_fbp')
                                    },
                                    content_category: "CDT Checkout",
                                    content_name: "Filiação Cartão de Todos",
                                    num_items: 1,
                                    custom_data: {
                                        currency: "BRL",
                                        value: total,
                                        payment_method: "Cartão de Crédito"
                                    }
                                }
                            ]
                        }
                    }
                    if (getFbPixelDebug().has)
                        payload.data.test_event_code = getFbPixelDebug().value;

                    MarketingConversionService().sendData(payload);

                    var successDTO = new StepDTO();

                    successDTO.user = configuration.ga_codigo;
                    successDTO.data = {
                        form: getForm()
                    }

                    AnalyticsService().SendAnalytics(
                        {
                            event: "Purchase",
                            channel: URLCurrent,
                            parameters: JSON.stringify({
                                // payment_method: ConvertMethod(methodSelected),
                                payment_method: formCartao.method === 'credito' ? 'credit-card' : formCartao.method === 'debito' ? 'debit' : 'pix',
                                message: resp.message ? resp.message : null
                            }),
                            source: utm_Source ? utm_Source : null,
                            campaign: utm_Campaign ? utm_Campaign : null,
                            url: window.location.origin,
                            lead: {
                                name: dto.nome,
                                email: dto.email,
                                purchased: resp.success,
                                registrationCode: resp.data.matricula,
                                ctnId: resp.data.idFiliado
                            }
                        }
                    );


                    if (getParam("utm_source=").value?.toLocaleLowerCase() === "kwanko") {
                        //Kwanko Transaction
                        const script = document.createElement('script');
                        script.src = 'https://img.metaffiliation.com/u/24/p77473.js';
                        document.head.appendChild(script);

                        window.ptag_params = {
                            zone: 'transaction',
                            products: [
                                {
                                    id: "1",
                                    price: total,
                                    quantity: '1'
                                },
                            ],
                            transactionId: resp.data.matricula,
                            currency: 'BRL'
                        };

                        KwankoService().KwankoTracking({
                            amount: total,
                            eventID: resp.data.tid,
                            payname: formCartao.method,
                            currency: 'BRL',
                            voucherCode: dto.voucher ? dto.voucher : ''
                        });
                    }

                    // ActionService().sendSuccess(successDTO);
                    if (formCartao.method === 'pix') {
                        if (navigate != null) navigate("/sucesso-pix" + window.location.search, { state: { configuration, matricula: resp.data.matricula, refuturizaCheckout: resp.refuturizaCheckout, dto: getForm() } });
                    }
                    else {
                        if (navigate != null) navigate("/sucesso" + window.location.search, { state: { configuration, matricula: resp.data.matricula, refuturizaCheckout: resp.refuturizaCheckout, dto: getForm() } });
                    }
                }
                else {
                    success = false;
                    dto.pagamento = undefined;

                    DataLayerError(idpv);

                    AnalyticsService().SendAnalytics(
                        {
                            event: "PurchaseError",
                            channel: URLCurrent,
                            parameters: 'Erro ao processar o pagamento, verifique os dados do cartão',
                            source: utm_Source ? utm_Source : null,
                            campaign: utm_Campaign ? utm_Campaign : null,
                            idpv: idpv ? idpv : null,
                            url: window.location.origin,
                            lead: {
                                name: dto.nome,
                                email: dto.email,
                            }
                        }
                    );

                    var failedDTO = new StepDTO();

                    failedDTO.user = configuration.ga_codigo;
                    failedDTO.data = {
                        form: getForm()
                    }

                    // para testar modal
                    // if (navigate != null) navigate("/sucesso" + window.location.search, { state: { configuration, dto, total: total } });

                    if (navigate != null) navigate("/analise" + window.location.search, { state: { configuration, dto, total: total } });

                    /**
                     * Quiz Cartão de todos
                     * - Faz o redirecionamento para o quiz de formas de pagamento caso o cartão do cliente seja recusado por qualquer motivo.
                     */
                    // if(window.location.host.search('idpv.') >= 0) {
                    //     if (navigate != null) navigate("/analise" + window.location.search, { state: { configuration, dto, total: total } });
                    // }
                    // else  {
                    //     const formData = getForm();
                    //     var userData = window.btoa(JSON.stringify({name: formData.nome, phone: formData.telefone ,email: formData.email}));
                    //     if(userData) return window.location.href = `https://quiz.${process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'dev.' : ''}cartaodetodos.com.br/meio-pagamento/?app_data=${userData}`;
                    // }
                }
            }
            else {
                let dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'erroPagamento',
                    'statusCode': '402',
                    'description': 'reCaptcha refused',
                });
            }

            alterarLoading(false);
        }

        return success;
    }

    useEffect(() => {
        if (voucherInvalido === false && nome && email) {
            const voucherInUppercase = VoucherTratado.toUpperCase();

            var form = getForm();
            LeadService().sendLead(form);
            

            AnalyticsService().SendAnalytics(
                {
                    event: "VoucherInserted",
                    channel: URLCurrent,
                    parameters: voucherInUppercase,
                    source: utm_Source ? utm_Source : null,
                    campaign: utm_Campaign ? utm_Campaign : null,
                    idpv: idpv ? idpv : null,
                    url: window.location.origin,
                    lead: {
                        name: nome,
                        email: email,
                    }
                }
            );
        }

    }, [VoucherTratado, voucherInvalido, nome, emailValido])


    function ConvertMethod(method: string) {
        let newName: any = null;

        switch (method) {
            case 'debito':
                newName = 'debit';
                break;

            case 'credito':
                newName = 'credit-card';
                break;

            case 'pix':
                newName = 'pix';
                break;

            default:
                newName = null;
                break;
        }
        return newName;
    }


    useEffect(() => {
        if (email && nome) {
            AnalyticsService().SendAnalytics(
                {
                    event: "PaymentMethodSelected",
                    channel: URLCurrent,
                    parameters: ConvertMethod(methodSelected),
                    source: utm_Source ? utm_Source : null,
                    campaign: utm_Campaign ? utm_Campaign : null,
                    idpv: idpv ? idpv : null,
                    url: window.location.origin,
                    lead: {
                        name: nome,
                        email: email,
                    }
                }
            );
        }

    }, [methodSelected])


    async function sendGt7Analytics() {
        AnalyticsService().SendAnalytics(
            {
                event: "LeaveCheckout",
                channel: URLCurrent,
                parameters: 'Fechou checkout',
                source: utm_Source ? utm_Source : null,
                campaign: utm_Campaign ? utm_Campaign : null,
                idpv: idpv ? idpv : null,
                url: window.location.origin,
                lead: {
                    name: nome,
                    email: email,
                }
            }
        );
    }

    useEffect(() => {
        if (nomeValido && cpfValido && emailValido && telefoneValido && (termos && !termosInvalido) && (maiorDezoito && !maiorDezoitoInvalido) && termosSmsEmail && validEmailCTN) {
            // if (nomeValido && emailValido && telefoneValido && (termos && !termosInvalido) && (maiorDezoito && !maiorDezoitoInvalido) && termosSmsEmail && validEmailCTN) {
            var form = getForm();
            LeadService().sendLead(form);
            if (!cpfCadastradoCDT) {
                desabilitarPessoa();
                alterarCanFocusCep(true);
            }
            else {
                // desabilitarEndereco();
                // alterarHabilitadoPessoa(false);
                // alterarHabilitadoEndereco(false);
                // setModalRefiliacaoShow(true);
            }
        }
    }, [nomeValido, cpfValido, emailValido, telefoneValido, termosInvalido, maiorDezoitoInvalido, termosSmsEmail, validEmailCTN, cpfCadastradoCDT])

    return {
        configuration, handleSubmit, desabilitarPessoa, desabilitarEndereco,

        nome, alterarNome, verificarNome, nomeValido, nomeInvalido, nomeMensagemErro,
        cpf, alterarCpf, verificarCpf, cpfValido, cpfInvalido, cpfMensagemErro,
        email, alterarEmail, verificarEmail, emailValido, emailInvalido, emailMensagemErro,
        telefone, alterarTelefone, verificarTelefone, telefoneValido, telefoneInvalido, telefoneMensagemErro,
        termos, alterarTermos, verificarTermos, termosInvalido, termosMensagemErro, termosSmsEmail, alterarTermosSmsEmail,
        cep, alterarCep, verificarCep, cepValido, cepInvalido, cepMensagemErro,
        rua, alterarRua, verificarRua, ruaValido, ruaInvalido, ruaMensagemErro,
        bairro, alterarBairro, verificarBairro, bairroValido, bairroInvalido, bairroMensagemErro,
        numero, alterarNumero, verificarNumero, numeroValido, numeroInvalido, numeroMensagemErro,
        complemento, alterarComplemento,
        cidade, alterarCidade, estado, alterarEstado,
        habilitadoPessoa, habilitadoEndereco, posicaoAccordion, getForm, sendOptIn, mensalidadeComp, alterarMensalidadeComp, adesaoComp, alterarAdesaoComp,
        voucher, alterarVoucher, descontoMensalidade, descontoAdesao, alterarDescontoMensalidade, alterarDescontoAdesao,
        modalShow, setModalShow, modalRefiliacaoBlockShow, setModalRefiliacaoBlockShow, modalRefiliacaoShow, setModalRefiliacaoShow, loadingCep, alterarVoucherInvalido, loading, alterarLoading,
        dataNasc, alterarDataNasc, verificarDataNasc, dataNascValido, dataNascInvalido, dataNascMensagemErro,
        maiorDezoito, alterarMaiorDezoito, verificarMaiorDezoito, maiorDezoitoInvalido, maiorDezoitoMensagemErro,
        canFocusCep, alterarCanFocusCep, canFocusNumero, alterarCanFocusNumero,
        voucherDescription, alterarVoucherDescription, voucherInvalido,
        recaptchaRef,
        processoPagamento, alterarProcessoPagamento,
        sendGt7Analytics,
        showModalSergipe, setShowModalSergipe,
        isCepSergipe, setIsCepSergipe,
        modalRefiliacaoMessageShow, setModalRefiliacaoMessageShow,
        modalRefiliacaoCepShow, setModalRefiliacaoCepShow,
        idFiliadoRefiliacao
    };
}