import { Modal, Row, Col } from "react-bootstrap";
import { ReactComponent as IconFechar } from "../assets/icons/icon-close.svg";

export const ModalTermosComponenteSergipe = (props: any) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h1 className="text-uppercase text-center modal-title h4 mb-3">
              Termos e Condições
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="p1">
                <b>
                Cartão de desconto não é plano de saúde, não garante e não se
                responsabiliza pelos
                serviços oferecidos e pelo pagamento das despesas, nem assegura
                desconto em todos os serviços obrigatoriamente garantidos por
                plano de saúde. Tudo que o cliente usar ou
                comprar será por ele pago ao prestador, assegurando-se apenas os
                preços e descontos que constam na relação de empresas e serviços
                conveniados divulgados no site
                 <u><a href="http://www.cartaodetodos.com.br/"> www.cartaodetodos.com.br</a></u>
                </b>
            </p>
            <p className="p1">
              <br />
            </p>
            <p className="p1">
                    Art. 1º - São oferecidos ao ADERENTE, através do presente
                    Contrato de Adesão e seus anexos, convênios com empresas de
                    prestação de serviços nas áreas de saúde (consultas médicas,
                    exames clínicos e tratamentos odontológicos), educação
                    (cursos profissionalizantes e educação escolar) e lazer
                    (hotéis e clubes), a custos reduzidos disponibilizados pelo
                    CNPJ:__________________________________ aqui denominado
                    Cartão de TODOS.
            </p>
            <p className="p1">
                    §1º: O Cartão de TODOS não se responsabiliza pela qualidade
                    técnica e profissional dos serviços prestados pelas empresas
                    conveniadas, bem como pelo recebimento dos
            </p>
            <p className="p1">
            §2º: O ADERENTE declara ter recebido, no momento da
            celebração do presente Contrato de Adesão, a lista de todas as empresas conveniadas com o Cartão de TODOS especificado no caput do Art. 1º. Havendo mudança ou substituição do prestador de serviço conveniado, o Cartão de TODOS informa que a atualização da lista de empresas poderá ser acessada em seu sítio eletrônico oficial www.cartaodetodos.com.br, bem como através do envio de comunicação eletrônica ao ADERENTE que tiver fornecido ao Cartão de TODOS o seu endereço eletrônico para correspondência no momento da assinatura do contrato.
            </p>
            <p className="p1">
            §3º: Terá direito ao acesso às empresas conveniadas o ADERENTE e seus familiares (cônjuge e filhos até a idade de 21 anos completos), desde que devidamente inscritos junto ao Cartão de TODOS.
            </p>
            <p className="p1">
            §4º: Somente o ADERENTE que realizar o pagamento da 1ª mensalidade e que esteja rigorosamente em dia com as suas obrigações financeiras junto ao Cartão de TODOS, terá direito aos serviços e vantagens por ele intermediados.
            </p>
            <p className="p1">
            §5º. Inclui-se como vantagem ao titular ADERENTE rigorosamente adimplente, maior de 18 anos e com menos de 61 anos no ato da adesão, que tenha quitado o mínimo de 2 (duas) mensalidades, o Auxílio Funeral, seguro de vida que tem por objeto o pagamento de R$1.500,00 (mil e quinhentos reais) no caso de morte natural e acidental, conforme condições gerais e especiais entregues ao ADERENTE neste ato e que também se encontra disponível no site https://www.cartaodetodos.com.br/seguro_funeral_chubb.pdf, com termino de vigência no dia 31/12 do ano de assinatura deste contrato, podendo o referido prazo ser prorrogado a critério do Cartão de TODOS, que comunicará em jornal de grande circulação caso não haja prorrogação.
            </p>
            <p className="p1">
            §6º: O seguro é garantido pela CHUBB SEGUROS BRASIL S.A., Código Susep 651-3, CNPJ z03.502.099/0001-18, Processo Susep Seguro de Pessoas nº 15414.005368/2006-73, e que a aceitação do seguro estará sujeita à análise de risco, e o registro deste plano na Susep não implica, por parte da autarquia, incentivo ou recomendação a sua comercialização.
            </p>
            <p className="p1">
            §7º Ao celebrar este Contrato, o ADERENTE fornece ao Cartão de TODOS o seu nome, CPF, endereço, telefone e de seus dependentes e concorda expressamente, nos termos da Lei Geral de Proteção de Dados, no tratamento destes dados para informação aos parceiros do Cartão de TODOS e concessão dos descontos contratados, para realização de cobranças, e para envio de publicidades com descontos de interesse do ADERENTE.
            </p>
           
            <p className="p1">
            Art. 2º – O ADERENTE obriga-se a pagar ao Cartão de TODOS, a partir da assinatura deste, por si e por seus familiares inscritos, o valor mensal sucessivo de R$__________, mediante autorização de débito, conforme a sua exclusiva escolha (boleto bancário, cobrança em conta de energia, débito em conta de corrente, cartão de crédito ou pagamento direto na sede do Cartão de TODOS).*
            </p>
            <p className="p1">
            §1: O ADERENTE pagará, ao Cartão de TODOS, no ato da adesão, a Taxa de Emissão do Cartão de identificação** do ADERENTE no valor de R$__________, que não se confunde com a 1ª mensalidade.
            </p>
            <p className="p1">
            §2: O ADERENTE declara ter ciência que, caso opte por realizar a Emissão do Cartão de Identificação com a personalização de agremiações e Clubes patrocinados e licenciados pelo Cartão de TODOS através do Programa “TODOS Esportes”, deverá pagar anuidade equivalente a R$__________, a cada aniversário do contrato, devida por agremiação e/ou
Clubes patrocinados e licenciados.

            </p>
            <p className="p1">
            §3º: Os pagamentos mensais e sucessivos tratados no caput somente serão devidos, bem
como os serviços ora contratados somente serão disponibilizados, após o pagamento da 1ª
mensalidade através da forma de arrecadação elegida pelo cliente.

            </p>
            <p className="p1">
            §4º: É de inteira responsabilidade do ADERENTE, manter o Cartão de TODOS informado sobre quaisquer alterações no cadastro e na forma de cobrança
            </p>
            <p className="p1">
            §5ºO reajuste anual da mensalidade, mencionada no caput, e da anuidade referente ao Programa “TODOS Esportes”, mencionada no §2º, ocorrerá em janeiro de cada ano de acordo com o IGPM integral da FGV do ano anterior, sendo informado aos ADERENTES com no mínimo 30 (trinta) dias de antecedência de sua efetivação, por meio do sítio eletrônico www.cartaodetodos.com.br, bem como pelo envio de comunicação eletrônica àqueles clientes que tiverem fornecido o seu endereço eletrônico no momento da assinatura deste contrato.
            </p>
            <p className="p1">
            §6º:0 Cartão de TODOS não se responsabiliza pelas informações prestadas pelo ADERENTE no momento da assinatura do contrato, reservando-se no direito de regresso, em caso de fraude.
            </p>
           
            <p className="p1">
            Art. 3º – O presente contrato tem validade pelo prazo de 12 (doze) meses, contados do pagamento da 1ª mensalidade conforme Art. 2º, §3º do presente, sendo as mensalidades
renovadas, automaticamente, por prazo indeterminado, caso não haja manifestação expressa em contrário por uma das partes.

            </p>
            <p className="p1">
            §1º: O ADERENTE poderá rescindir o presente contrato sem quaisquer ônus no prazo de 07 (sete) dias contados da data de sua assinatura.
            </p>
            <p className="p1">
            §2º: Após a renovação automática do presente contrato por prazo indeterminado, o mesmo poderá ser rescindido por qualquer uma das partes, sem multa, mediante comunicação prévia de 30 (trinta) dias, em qualquer uma das unidades do Cartão de TODOS ou pelos canais disponíveis no site do Cartão de TODOS.
            </p>
            <p className="p1">
            §3º: No caso de inobservância dos 12 (doze) meses previstos no caput, será devida multa equivalente a 50% sobre o valor da soma das mensalidades vincendas.
            </p>
            <p className="p1">
            §4º: A rescisão do presente instrumento só será efetivada, em qualquer hipótese, mediante
o pagamento de todas as mensalidades em atraso.

            </p>
            <p className="p1">
            §5º: A anuidade mencionada no §2 do art. 2° será renovada automaticamente e por igual
prazo, a cada 12 (doze) meses, salvo na hipótese do ADERENTE requerer o cancelamento
da cobrança em até 30 (trinta) dias do fim do prazo de validade do contrato.

            </p>
            <p className="p1">
            §6º: Fica ressalvado o direito de cobrança extrajudicial e judicial, pelo Cartão de TODOS,
da(s) mensalidade(s) não quitada(s) e em atraso pelo ADERENTE, acrescido de multa de 2% e juros moratórios de 1% a.m.

            </p>
            <p className="p1">
            §7º: A suspensão ou cancelamento das cobranças das mensalidades não implica em cancelamento do contrato de adesão ou renúncia do Cartão de TODOS ao seu direito de cobrar a mensalidade do ADERENTE por outro meio.
            </p>
            <p className="p1">
            Art. 4º – Na hipótese de cobrança de mensalidade em conta de energia:
            </p>
            <p className="p1">
            §1º: O Cartão de TODOS obriga-se a suspender, imediatamente, as cobranças de mensalidades sempre que tal pedido for requisitado pelo consumidor diretamente à concessionária de energia, e desde que tal fatura já não tenha sido emitida antes da solicitação de cancelamento, sob pena de devolução em dobro dos valores arrecadados
indevidamente, nos termos da Resolução 1.000/2021da ANEEL.

            </p>
            <p className="p1">
            §2º: Na hipótese de cobrança indevida prevista no parágrafo anterior, o ADERENTE poderá dirigir-se a qualquer uma das unidades do Cartão de TODOS para restituição do valor. A devolução ou crédito em mensalidades futuras, à inteira escolha do consumidor, será processada em até 72 (setenta e duas) horas após a apresentação do comprovante de pagamento da conta de energia elétrica e o protocolo de atendimento da concessionária
solicitando o cancelamento da cobrança da mensalidade.

            </p>
            <p className="p1">
            §3º: O ADERENTE autoriza a alteração/atualização dos dados de titularidade da Conta de Energia junto a Concessionária de Energia.
            </p>
            <p className="p1">
            §4: É de inteira e exclusiva responsabilidade do ADERENTE, em hipótese de residir em imóvel locado, comunicar ao proprietário do imóvel sobre as obrigações decorrentes da assinatura deste contrato.
            </p>
            <p className="p1">
            Art. 5º – O ADERENTE se declara esclarecido e de acordo com as cláusulas do mediante autorização de débito em anexo ao presente contrato, bem como está ciente de que o cartão de desconto não é plano de saúde, não garante e não se responsabiliza pelos serviços oferecidos e pelo pagamento das despesas, nem assegura desconto em todos os serviços obrigatoriamente garantidos por plano de saúde. Tudo o que o cliente usar ou comprar será
por ele diretamente pago ao prestador, assegurando-se apenas os preços e descontos que constam na relação de empresas e serviços conveniados e divulgados no sítio eletrônico www.cartaodetodos.com.br.

            </p>
            <p className="p1">
            Art. 6º – O presente contrato deverá ser interpretado de acordo com as regras previstas no Código de Defesa do Consumidor.
Parágrafo único: Nesta ocasião o Aderente recebe 01 (uma) via do contrato de adesão firmado entre as partes.

            </p>
            <p className="p1">
            Art. 7º – As partes elegem o Foro da Comarca Local, com renúncia expressa qualquer outro, por mais privilegiado que seja ou venha a ser.
            </p>
            <p className="p1">
            Este é um Contrato específico para o Estado de Sergipe, em cumprimento da decisão judicial prolatada nos autos n.  0046182-68.2023.8.25.0001. Consulte as formas de pagamento disponíveis na sua região.
            </p>
            <p className="p1">
            ** O Cartão de Identificação poderá ser ativado a exclusivo critério do ADERENTE na modalidade crédito caso este contrate diretamente os serviços da empresa MaisTODOS,
por meio de contrato próprio e que não se confunde com o presente Contrato de Adesão.
ADESÃO

            </p>
          
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
